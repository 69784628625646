import { render, staticRenderFns } from "./AccessControl.vue?vue&type=template&id=6dcad9d3&"
import script from "./AccessControl.vue?vue&type=script&lang=js&"
export * from "./AccessControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2024/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6dcad9d3')) {
      api.createRecord('6dcad9d3', component.options)
    } else {
      api.reload('6dcad9d3', component.options)
    }
    module.hot.accept("./AccessControl.vue?vue&type=template&id=6dcad9d3&", function () {
      api.rerender('6dcad9d3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/extensions/acl/AccessControl.vue"
export default component.exports